import { on } from 'delegated-events';

import openModal from './modals';
import smallCartRefresh from './cart/small-cart-refresh';
import { showLoader, hideLoader } from './utils/utils';

export default function () {
    on('click', '.cartadder', function () {
        showLoader();

        const scope = this.closest('[data-scope="addcart"]');
        let amount;

        if (scope.querySelector('[data-role="product-amount"]')) {
            amount = Number(scope.querySelector('[data-role="product-amount"]').value) || 1;
        } else {
            amount = 1;
        }

        if (scope.querySelector('[data-optionid]') && scope.querySelector('[data-optionid]').getAttribute('data-optionid') === '-1') {
            openModal({ content: 'Proszę wybrać opcję produktu' });
            hideLoader();
            return;
        }

        const requestBody = {
            request: {
                Amount: amount,
                ProductId: scope.querySelector('[data-pid]').getAttribute('data-pid'),
                OptionId: scope.querySelector('[data-optionid]') ? scope.querySelector('[data-optionid]').getAttribute('data-optionid') : null
            }
        };

        const requestAddons = [];

        scope.querySelectorAll('[data-cart-custom]').forEach(dataCart => {
            requestAddons.push({
                key: dataCart.getAttribute('data-cart-custom'),
                value: dataCart.value
            });
        });
        console.log(requestBody);
        requestBody.request['addons'] = requestAddons;

            fetch('cartaction/addcart', {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(requestBody),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
            .then(response => response.json())
            .then(data => {

                hideLoader();

                if (data.RedirectToUrl) {
                    location.href = data.ProductUrl;
                    return false;
                }

                openModal({ content: data.HtmlResponse, customClass: 'cart-success', anotherCloseSelector: '.return-button' });

                if (data.PositionChanges) {
                    for (const item of data.PositionChanges) {

                        document.dispatchEvent(new CustomEvent('goshop:cart_action', { detail: item }));

                    }
                }


                smallCartRefresh();

            })
            .catch(error => {
                hideLoader();
                console.log(error);
            });
    });
}