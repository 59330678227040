import { on } from 'delegated-events';

import openModal from './modals';
import initRecaptcha from './recaptcha';

export default function () {
    const newsletter = document.getElementById('newsletter');

    if (newsletter) {
        newsletter.querySelector('.popup-trigger').addEventListener('click', function () {
            openModal({ content: newsletter.querySelector('.goshop-modal-content').innerHTML, customClass: 'standard-newsletter' });

            initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
        });

        newsletter.querySelector('input[type="text"]').addEventListener('keyup', function (e) {
            if ((e.key === "Enter" || e.keyCode === 13) && !window.currentModalInstance) {
                openModal({ content: newsletter.querySelector('.goshop-modal-content').innerHTML, customClass: 'standard-newsletter' });

                initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
            }
        });
    }
}