import * as basicLightbox from 'basiclightbox';
import { on } from 'delegated-events';

on('click', '.goshop-modal-close', function () {
    if (window.currentModalInstance) {
        window.currentModalInstance.close();
    }
});

export default function ({ content, customClass = '', anotherCloseSelector = null, startCallback = null, closeCallback = null }) {
    const instance = basicLightbox.create(`
        <div class="goshop-modal">
            ${content}
            <span class="goshop-modal-close"></span>
        </div>
    `, {
            className: `${customClass}`,
            onShow: instance => {
                if (anotherCloseSelector) {
                    instance.element().querySelector(anotherCloseSelector).onclick = instance.close;
                }

                if (startCallback) {
                    startCallback();
                }
            },
            onClose: () => {
                window.currentModalInstance = null;

                if (closeCallback) {
                    closeCallback();
                }
            }
        });

    window.currentModalInstance = instance;

    instance.show();
}